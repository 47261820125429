<div *ngIf="isNewComponent">
  <straiv-label [header]="description"></straiv-label>
  <div class="phone-container">
    <straiv-select name="phonecode" ngDefaultControl theme="dark" type = 'phone' combined="true" placeholder="+XX"
      [value]="selectedCountryCode" [items]="countries | json" [required]="required" [(ngModel)]="selectedCountryCode" (selectedEvent)="changeSelCountry($event?.detail?.selectedValue)">
    </straiv-select>
    
    <straiv-input name="phone" ngDefaultControl type="phone" [placeholder]="description" [required]="required"
      [(ngModel)]="phoneWithoutCountry" (valueChange)="emitPhoneChange($event)" (inputBlur)="handlePhoneInputBlur($event)">
    </straiv-input>
  </div>
  <straiv-error-label *ngIf="isPhoneIsNotValid" [errorMessage]="'validation.required' | translate"></straiv-error-label>
</div>

<div *ngIf="!isNewComponent" class="input-container" [ngClass]="{ 'valid': phoneWithoutCountry?.length > 0 && input.valid, 'invalid': ((input.dirty || submitted || phoneWithoutCountry?.length > 0) && input.invalid), 'disabled': disabled }">
  <app-description-label [for]="inputId" [description]="description" [required]="required && !disabled" [tooltip]="tooltip"></app-description-label>

  <div id="input" class="input-wrapper" (keydown.enter)="dropdownOpen ? closeDropdown() : openDropdown()" #countryList>
    <div id="country-dropdown" class="country-dropdown" [class.open]="dropdownOpen" (mousedown)="dropdownOpen ? closeDropdown() : openDropdown()" (clickOutside)="dropdownOpen ? closeDropdown() : null" (keydown.space)="dropdownOpen ? null : openDropdown()" (keydown.escape)="closeDropdown()" (click)="!dropdownOpen ? null : countryCodeInput.focus()">
      <input #countryCodeInput class="redesign visually-hidden" name="phoneCountrySearch" [(ngModel)]="searchString" (ngModelChange)="countryInputChange()" [required]="false">
      <div class="country-code">
        <ng-container *ngIf="selCountry && !dropdownOpen"><img class="country-icon" [alt]="selCountry?.name" [src]="selCountry?.flag"><span>+{{ selCountry?.phone }}</span></ng-container>
        <ng-container *ngIf="!selCountry || dropdownOpen"><i class="fa-solid fa-globe main-text"></i><span>+XX</span></ng-container>
        <i class="fa-solid fa-chevron-down dropdown-icon main-text"></i>
      </div>
    </div>

    <input [id]="inputId" [name]="name" class="redesign" #input="ngModel" type="tel" [(ngModel)]="phoneWithoutCountry" (ngModelChange)="emitValueChange()" (focus)="changeKeyboardState(true)" (blur)="handleInputBlur()" (focusout)="removeBlanks()" [placeholder]="placeholder || description" [autocomplete]="autocomplete" [required]="required" [disabled]="disabled" [attr.data-cy]="name" [phonevalidator]="{ validation: validation, required: required, countryCode: '+' + selCountry?.phone }">

    <div class="select-list thin-scroll" [ngClass]="{ 'visible': dropdownOpen, 'display-top': dropdownPosition === 'above' }">
      <div *ngFor="let country of list; let i = index" [id]="'country-item-' + i" (mousedown)="changeSelCountry(country)" (keydown.escape)="closeDropdown()" class="item-container" [class.selected]="country === selCountry">
        <img class="country-icon" [alt]="country?.name" [src]="country?.flag"> <span>{{ country.name }} (+{{country.phone}})</span>
      </div>
      <div *ngIf="list?.length === 0" class="not-found" translate>misc.no_match</div>
    </div>
    <i class="fa-solid fa-check checkmark-icon"></i>
  </div>

  <label class="error-label" [class.visible]="input && input.invalid && (input.dirty || submitted)">
    <i class="fa-solid fa-circle-xmark"></i>
    <span *ngIf="(!input.value || input.value.length == 0) || input.errors?.required" translate>validation.required</span>
    <span *ngIf="input.value && input.errors?.phonevalidator?.error">{{input.errors?.phonevalidator?.error | translate}}</span>
  </label>
</div>
