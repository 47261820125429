import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Action } from 'models/action';
import { PmsProcess } from 'pms_enums';

@Component({
  selector: 'app-pms-wizard-actions',
  templateUrl: './actions.component.html',
  styleUrls: ['./actions.component.scss']
})

export class PmsWizardActionsComponent {

  @Input() actions: Action[];
  @Output() actionClick = new EventEmitter();

  constructor() {}

  onActionClick(action: Action) {
    this.actionClick.emit(action.connection);
  }
}
