import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Globals } from 'base';
import { PmsCiCoService } from 'cico_service';
import { PmsService } from 'modules/pms/pms.service';
import { Subject, Subscription } from 'rxjs';
import { PassportPhotoService } from 'services/passport-photo.service';
import { PmsGuest } from 'models/pms/pms_guest';
import { ControlContainer, NgForm } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { GenericData } from 'models/pms/generic_data';
import { filter, take } from 'rxjs/operators';
import { EventAggregatorService } from 'services/events/event-aggregator.service';
import { EventConstants } from 'global_enums';

@Component({
  selector: 'app-passport-form',
  templateUrl: './passport-form.component.html',
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}],
  styleUrls: ['./passport-form.component.scss']
})

export class PassportFormComponent implements OnInit, OnDestroy {

  subscriptions: Subscription = new Subscription();
  photo: string;
  photoIsPdf = false;
  showPreview: boolean = false;
  previewSrc: string;
  showCamera: boolean = false;
  requiredDocType: boolean = false;
  guests: PmsGuest[];

  fields: any;
  passportFields: any;
  visaFields: any;
  fileUploadTrigger$: Subject<any> = new Subject<any>();
  passportImg: any;

  @ViewChild('passportForm') passportForm: ElementRef;

  @Input() guest: any;
  @Input() guestIndex: number;
  @Input() submited: boolean;
  @Output('ngModelChange') inputValueChange: EventEmitter<any> = new EventEmitter<any>();
  docTypes: any[] = [];

  passportErrorMsg: string;
  visaErrorMsg: string;
  passportDisabled: boolean = false;

  constructor(public globals: Globals,
              public pmsService: PmsService,
              public cicoService: PmsCiCoService,
              public passportPhotoService: PassportPhotoService,
              protected readonly eventService: EventAggregatorService,
              private _cdr: ChangeDetectorRef,
              private sanitizer: DomSanitizer) {
  }

  ngOnInit() {
    this.subscriptions.add(this.passportPhotoService.cameraVisible$.subscribe(visible => {
      this.showCamera = visible;
      this._cdr.detectChanges();
    }));

    this.subscriptions.add(this.passportPhotoService.photo$.subscribe(photo => {
      this.photo = photo;
    }));

    if (this.guest.valid_documents) {
      this.docTypes = this.guest.valid_documents.map(documentType => {
        return {name: this.globals.translate('service.check_in.documents.' + documentType), value: documentType};
      });
    }

    this.setFields();
    this.setRequiredDocTyp();
    this.passportImg = this.convertImage(this.guest.passport);
    this.photoIsPdf = this.isPdf(this.guest.passport);
  }

  setFields() {
    let passport, visa;
    if (this.guest.group === 'primary_guest') {
      passport = this.cicoService.field_for('primary_guest')?.subField('passport');
      visa = this.cicoService.field_for('primary_guest')?.subField('visa');
    } else {
      const subFieldKey = this.guest.adult() ? 'adult_fellows' : 'children_fellows';
      passport = this.cicoService.field_for('fellows')?.subField(subFieldKey)?.subField('passport');
      visa = this.cicoService.field_for('fellows')?.subField(subFieldKey)?.subField('visa');
    }

    this.passportFields = {
      passport_id: passport?.subField('passport_id'),
      passport_authority: passport?.subField('passport_authority'),
      passport_date: passport?.subField('passport_date'),
      passport_expire: passport?.subField('passport_expire'),
      passport_image: passport?.subField('passport_image')
    };

    this.visaFields = {
      visa_number: visa?.subField('visa_number'),
      visa_date: visa?.subField('visa_date'),
      visa_expire: visa?.subField('visa_expire')
    };
    this.fields = {...this.passportFields, ...this.visaFields};

    if (this.fields) {
      const duplicationDocTypes = ['passport_id', 'visa_number'];
      const filteredDocTypes = duplicationDocTypes.filter(docType => this.fields[docType]);

      filteredDocTypes.forEach(docType => this.handleDocumentChange('', docType));
    }
  }

  setRequiredDocTyp() {
    const settings = (this.guest.passport_data || this.guest.passport_image);
    this.requiredDocType = settings && Object.keys(this.passportFields).filter((key) => {
      return (key == 'passport_image' && (this.guest.passport_upload_required || this.guest.passport_image?.length)) ||
        (key !== 'passport_image' && this.guest.passport_data && (this.passportFields[key]?.required || this.guest[key]?.length));
    }).length > 0;
  }

  documentType(guest: PmsGuest): String {
    return guest.doc_type ? `(${this.globals.translate('service.check_in.documents.' + guest.doc_type)})` : '';
  }

  uploadedFilesChange(files: FileList) {
    Array.from(files).forEach((file: File) => {
      this.readFiles(file);
    });
  }

  readFiles(file: File) {
    const reader: FileReader = new FileReader();
    reader.onload = () => {
      this.passportPhotoService.setPhoto(reader.result);
    };
    reader.readAsDataURL(file);
  }

  addPassportDoc() {
    this.globals.kiosk() ? this.openCamera() : this.triggerFileUpload();
  }

  openCamera() {
    this.globals.clearAlert();
    this.passportPhotoService.removePhoto();
    this.passportPhotoService.changeCameraVisible(true);
  }

  triggerFileUpload() {
    this.fileUploadTrigger$.next();
  }

  onUploadSuccess(file: any) {
    this.photo = file;
    this.photoIsPdf = this.isPdf(this.photo);
    this.valueChange();
  }

  photoClick(photo: any) {
    this.showPreview = true;
    this.previewSrc = photo;
  }

  photoLongClick(photo: any) {
    if (window.screen.width <= 1320) {
      this.previewSrc = photo;
      this.showPreview = true;
    }
  }

  convertImage(img) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(img);
  }

  removePhoto() {
    this.guest.passport = null;
  }

  valueChange() {
    this.pmsService.documentIsValid(this.photo, this.guest.group).subscribe(() => {
      this.globals.alert('success', this.globals.translate('booking_data.passport_image.success'), this.globals.translate('service.process.success'));
      this.cicoService.cacheImage('passport', this.guest.uuid, this.photo);

      this.scrollToPassportForm();

      this.guest.passport = this.photo;
      this.passportImg = this.convertImage(this.photo);

    }, () => {
      this.globals.alert('error', this.globals.translate('validation.passport'), this.globals.translate('misc.error'));
    });
  }

  scrollToPassportForm() {
    this.passportForm.nativeElement.scrollIntoView({behavior: 'smooth'});
  }

  preventContextMenu(e) {
    e.preventDefault();
    e.stopPropagation();
    e.stopImmediatePropagation();
    return false;
  }

  isPdf(file: any) {
    return file?.startsWith('data:application/pdf');
  }

  handleDocumentChange(event: any, field: string) {
    // Clear previous error messages
    this.passportErrorMsg = '';
    this.visaErrorMsg = '';

    this.subscriptions.add(this.cicoService.data.pipe(filter(Boolean), take(1)).subscribe((data: GenericData) => {
      let index = data.incident.reservation.duplicateIndex(this.guest, field, event?.detail);
      if (index !== -1 ) {
        if (field === 'passport_id') {
          this.passportErrorMsg = this.globals.translate(`validation.duplicate_passport`);
        } else {
          this.visaErrorMsg = this.globals.translate(`validation.duplicate_visa`);
        }
      }
    }));
  }

  onBlurPassportNumber() {
    this.handleBlurDuplication('passport_id', this.passportErrorMsg);
  }
  
  onBlurVisaNumber() {
    this.handleBlurDuplication('visa_number', this.visaErrorMsg);
  }

  private handleBlurDuplication(field: string, errorMsg: string) {
    const data = {
      field: field,
      error: errorMsg ? `duplicate_${field === 'passport_id' ? 'passport' : 'visa'}` : null,
    };
    this.eventService.getEvent(EventConstants.formCustomValidation).publish(data);
  }
  
  onSelectedDocType(event: any) {
    if (event?.detail?.selectedValue) {
      this.guest.doc_type = event.detail.selectedValue.value;
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
