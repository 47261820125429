import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { AddressType } from 'app/_enums/pms/enums';
import { PmsCiCoService } from 'cico_service';
import { Address } from 'models/address';
import { Tech } from 'models/tech';
import { GenericData } from 'pms_models/generic_data';
import { PmsFolio } from 'pms_models/pms_folio';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-pms-invoice-address-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}]
})

export class PmsInvoiceAddressFormComponent implements OnInit, OnChanges, OnDestroy {

  protected readonly AddressType = AddressType;
  protected subscriptions: Subscription = new Subscription();

  @Input() folio: PmsFolio;
  @Input() data: GenericData;
  @Input() staticAddress: boolean;
  @Input() number: number;
  @Input() newDesign: boolean;
  @Input() withoutFolio: boolean;
  @Input() deviatingBillingAdr: boolean;
  @Input() submited: boolean;
  @Output() dataEvent = new EventEmitter<boolean>();

  addresses: Address[] = [];
  usedAddress: Address;
  guestAddress: Address;
  currentAddress: Address;
  fields: any;
  fieldsInactive: boolean;
  pmsSystem: Tech;
  states = [];
  addr = 0;
  maxAdresses = 4;
  reference: any;
  combined: boolean;
  address_lock: boolean;
  createNewAdr: boolean = true;

  constructor(public cicoService: PmsCiCoService,
              private _cdr: ChangeDetectorRef
  ) { }

  ngOnChanges(): void {
    if (this.addresses.length) {
      this.fillAddresses();
    }
  }

  ngOnInit() {
    this.fields = this.deviatingBillingAdr ? this.cicoService.field_for('primary_guest').subField('invoice_address') : this.cicoService.field_for('invoice_address');
    this.fieldsInactive = !this.fields?.active;

    if (!this.addresses.length) {
      this.guestAddress = this.data.incident.reservation.primary_guest.address;
      this.combined = this.data.module.settings.invoice_address === 'combined';
      this.pmsSystem = this.data.business?.tech?.pms_system;

      this.collectAddresses();

      this.address_lock = this.folio?.address_lock || this.suite8Lock();

      this.fillAddresses();
    }
    this.usedAddress = this.folio ? this.folio.address : this.data.incident.reservation.address;

    if (this.deviatingBillingAdr) {
      this.addNewAdrs();
    }

    this.usedAddress.address_type = this.usedAddress.company?.length > 0 ? AddressType.company : AddressType.personal;
  }

  isStaticAddress(): boolean {
    return this.staticAddress || this.folio?.existing_payments || (this.noPayment() && this.isReservationModule());
  }

  noPayment(): boolean {
    return this.folio?.payment === 'no_payment';
  }

  folioPaid(): boolean {
    return this.folio?.check === 'paid';
  }

  suite8Lock(): boolean {
    return this.data.business.usePms('suite8') && this.folioPaid() && this.folio.positions.length > 0;
  }

  isReservationModule(): boolean {
    return this.data?.isReservationModule();
  }

  updateAddressType() {
    if (this.usedAddress.address_type === AddressType.personal) {
      this.usedAddress.company = null;
    }
    this.updateAddress(this.usedAddress);
  }

  updateAddress(usedAddress) {
    if (this.folio) {
      this.folio.address = usedAddress;
    } else {
      this.data.incident.reservation.address = usedAddress;
    }
  }

  collectAddresses() {
    this.fixAdresses();
    this.reference = this.folio ? this.data.folioAddresses.find(fol => fol.number === this.folio.number) : this.data.incident.reservation;
    const empty = this.reference?.address?.isEmpty();
    const same = this.reference?.address?.isSame(this.guestAddress) || false;

    if (!empty) {
      if (same) {
        this.addresses.push(this.guestAddress);
      } else {
        this.addresses.push(this.reference.address);
      }
    }
    if (empty || !same) {
      if (!this.isReservationModule()) {
        if (this.guestAddress.isEmpty() && this.combined) {
          this.guestAddress.manual = true;
        }
        if (!this.folio?.address_lock || this.addresses.length === 0) {
          this.addresses.push(this.guestAddress);
        }
      } else {
        if (!this.addresses[0]?.isSame(this.reference.address)) {
          this.addresses.push(this.reference.address);
        }
      }
    }

    if (this.pmsSystem?.onPremise) {
      const ids = this.addresses.map((address) => address.internal_id);
      const others = this.data.incident.reservation.allFolios.filter((folio) => folio.viewable && !folio.address_lock && folio.address.internal_id?.length && !ids.includes(folio.address.internal_id)).map((folio) => folio.address);
      this.addresses = this.addresses.concat(others);
    }

    const existingManualAddress = this.addresses.find(address => address.manual);

    if (!existingManualAddress && !this.data.incident.reservation.address?.isEmpty() && !this.isStaticAddress() && this.cicoService.deviatingBillingAddress) {
      this.addresses.push(this.data.incident.reservation.address);
      this.addressChooser(this.addresses.length - 1);
    }

    if (this.isStaticAddress() && this.deviatingBillingAdr) {
      this.usedAddress = this.addresses[0];
    }
  }

  fixAdresses() {
    this.data.folioAddresses.forEach(folioAdress => {
      folioAdress.address = folioAdress.address instanceof Address ? folioAdress.address : new Address(folioAdress.address);
    });
  }

  fillAddresses() {
    this.fillMissingNames();
    if (this.addresses.length > 0 && !this.addresses[0].valid()) {
      this.addresses[0].manual = true;
    }
  }

  addressChooser(index) {
    setTimeout(() => {
      this.addr = index;
      this.usedAddress = this.addresses[index];

      if (!this.addresses[0].address) {
        this.usedAddress.first_name = this.addresses[0].first_name;
        this.usedAddress.last_name = this.addresses[0].last_name;
        this.usedAddress.company = this.addresses[0].company;
      }

      if (this.folio) {
        this.folio.address = this.usedAddress;
      }

      this.cicoService.deviatingBillingAddress = this.usedAddress === this.data.incident.reservation.address ? true : null;

      this._cdr.detectChanges();
    });
  }

  fillMissingNames() {
    const primaryGuest = this.data?.incident?.reservation?.primary_guest;
    if (this.folio && !this.folio.address?.company?.length && this.folio.address?.address?.length) {
      if (!this.folio.address?.first_name?.length) {
        if (!this.folio.address) {
          this.folio.address = new Address(null);
        }
        this.folio.address.first_name = primaryGuest?.first_name;
      }
      if (!this.folio.address?.last_name?.length) {
        this.folio.address.last_name = primaryGuest?.last_name;
      }
    }
  }

  editInvoiceAdr() {
    if (!this.folio?.address_lock) {
      this.cicoService.hideAddressList = false;
      this.dataEvent.emit(true);
      this.cicoService.setFolioAddressStep('select');
      this.cicoService.displayedFolioIndex = this.number;
    }
  }

  addNewAdrs() {
    this.cicoService.hideAddressList = true;

    if (this.deviatingBillingAdr) {
      this.data.incident.reservation.address.manual = true;
      this.usedAddress = this.data.incident.reservation.address;
    } else {
      const existingManualAddressIndex = this.addresses.findIndex(address => address.isEmpty() && address.manual);

      if (existingManualAddressIndex !== -1) {
        this.addressChooser(existingManualAddressIndex);
      } else {
        const newAddress = new Address({manual: true, address_type: AddressType.personal});
        this.addresses.push(newAddress);
        this.addressChooser(this.addresses.length - 1);
      }
      this.cicoService.setFolioAddressStep('create');
    }
  }

  isAddressSelected(i) {
    return this.usedAddress === this.addresses[i];
  }

  editAddress(index) {
    this.cicoService.hideAddressList = true;
    this.addressChooser(index);
    this.cicoService.setFolioAddressStep('create');
  }

  deleteAddress(index) {
    this.createNewAdr = true;
    this.cicoService.hideAddressList = true;
    this.addresses.splice(index, 1);
    this.addressChooser(0);
    this.cicoService.setFolioAddressStep('folio');
    this.cicoService.addressChange = false;
  }

  handleCountryChange(updateAddress: any) {
    this.updateAddress(updateAddress);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
