import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Globals } from 'base';
import { Business } from 'models/business';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { BusinessService } from 'services/business.service';
import { WebsocketService } from 'services/websocket/websocket.service';

@Component({
  selector: 'app-widget-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss']
})
export class WidgetLogoComponent implements OnInit, OnDestroy {
  subscriptions: Subscription = new Subscription();
  business: Business;

  wizard: boolean;
  loaded: boolean;
  socketConnected: boolean;

  logo_upright = false;
  title: string;

  @Input() fillContainer = true;

  constructor(public globals: Globals,
              private wsService: WebsocketService,
              private businessService: BusinessService) { }

  ngOnInit() {
    this.businessService.current_business.pipe(filter(Boolean)).subscribe((business: Business) => {
      this.business = business;
      this.logo_upright = this.business.logo_upright;
    });

    this.wizard = this.globals.place?.wizard;
    if (this.wizard) {
      this.title = this.globals.place.name;

      this.subscriptions.add(this.wsService.online?.subscribe((connected: boolean) => {
        this.socketConnected = connected;
        this.loaded = true;
      }));
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
