import { Component, KeyValueDiffers, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConfirmName, PmsModType } from 'pms_enums';
import { GuestService } from 'services/guest.service';
import { OfflineService } from 'services/offline.service';
import { StorageService } from 'services/storage.service';
import { WebsocketService } from 'services/websocket/websocket.service';
import { ModalService } from 'common/modal/modal.service';
import { Globals } from 'base';
import { PmsCiCoBaseDirective } from 'pms_base/cico_base.directive';
import { PmsCiCoService } from 'cico_service';
import { PmsPaymentService } from 'payment_service';
import { fadeInAnimation } from 'app/route-animations';
import { filter } from 'rxjs/operators';
import { GenericData } from 'pms_models/generic_data';
import { EventAggregatorService } from 'services/events/event-aggregator.service';
import { StepperService } from 'services/pms/stepper.service';

@Component({
  selector: 'app-check-in',
  templateUrl: './check_in.component.html',
  styleUrls: ['./check_in.component.scss'],
  animations: [fadeInAnimation]
})

export class PmsCheckInComponent extends PmsCiCoBaseDirective implements OnInit, OnDestroy {
  constructor(protected guestService: GuestService,
              protected differs: KeyValueDiffers,
              protected storageService: StorageService,
              protected route: ActivatedRoute,
              protected offlineService: OfflineService,
              protected wsService: WebsocketService,
              protected modalService: ModalService,
              protected paymentService: PmsPaymentService,
              public cicoService: PmsCiCoService,
              protected readonly eventService: EventAggregatorService,
              protected stepperService: StepperService,
              public globals: Globals) {
    super(cicoService, guestService, differs, storageService, globals, modalService, paymentService, route, offlineService, wsService, eventService, stepperService);
  }

  ngOnInit() {
    super.ngOnInit(PmsModType.ci);

    this.subscriptions.add(this.cicoService.data.pipe(filter(Boolean)).subscribe((data: GenericData) => {
      this.data = data;

      if (!this.data.module.settings.can_cus) {
        this.cicoService.cusLoadedSubj.next(true);
        return;
      }

      this.cicoService.cusLoadedSubj.next(false);

      this.data.folioInfo.loaded.pipe(filter(Boolean)).subscribe(() => {
        if (this.data.incident.reservation.folios.length) {
          this.cicoService.loadCus(this.data.incident.reservation.uuid);
        } else {
          this.cicoService.cusLoadedSubj.next(true);
        }
      });
    }));

    this.subscriptions.add(this.cicoService.navigation.subscribe(_type => {
      this.cicoService.confirmNameSubj.next(ConfirmName.next);
    }));
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
