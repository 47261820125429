<div class="segment-button" *ngIf="fields.address_type && isBillingAddress">
  <straiv-label [header]="fields.address_type.name"></straiv-label>
  <straiv-segment-button ngDefaultControl id="address_type" name="address_type" required="true" [leftText]="'service.folios.private_address' | translate"
    [rightText]="'service.folios.company_address' | translate" left-value="personal" right-value="company" [(ngModel)]="guestAddress.address_type"
    [value]="guestAddress.address_type ? ('service.folios.company_address' | translate) : ('service.folios.private_address' | translate)"
    (buttonClick)="addressToggle($event)">
  </straiv-segment-button>
</div>

<div class="country-grid" *ngIf="fields.country && data.countries?.length">
  <straiv-select ngDefaultControl name="invoice_country" [labelText]="fields.country.name" [items]="data.countries | json" 
    [value]="guestAddress.country" [required]="fields.country.required && guestAddress?.manual" [errorMessage]="'validation.country' | translate"
    [(ngModel)]="guestAddress.country" (selectedEvent)="onSelectedCountry($event)">
  </straiv-select>

  <straiv-select *ngIf="states?.length" ngDefaultControl name="invoice_state" 
    [labelText]="'address.state' | translate" [items]="states | json" [value]="guestAddress.state"
    [placeholder]="'address.state' | translate" [required]="currentAddress?.manual" 
    [errorMessage]="'validation.state' | translate" [(ngModel)]="guestAddress.state" (selectedEvent)="onSelectedState($event)">
  </straiv-select>
</div>

<straiv-input *ngIf="fields.company && guestAddress.address_type === AddressType.company && isBillingAddress" ngDefaultControl name="invoice_company"
  [labelText]="fields.company.name"  [placeholder]="fields.company.name" [required]="guestAddress.address_type === AddressType.company" 
  [errorMessage]="'validation.company' | translate" [(ngModel)]="guestAddress.company" (valueChange)="updateAddress()">
</straiv-input>

<div class="two-col-grid" *ngIf="isBillingAddress">
  <straiv-input *ngIf="fields.first_name?.active" ngDefaultControl id="invoice_first_name" name="invoice_first_name"
    [labelText]="fields.first_name.name" [placeholder]="fields.first_name.name" [errorMessage]="'validation.first_name' | translate"
    [required]="fields.first_name.required && guestAddress?.manual && guestAddress.address_type !== AddressType.company" 
    [(ngModel)]="guestAddress.first_name" (valueChange)="updateAddress()">
  </straiv-input>
  
  <straiv-input *ngIf="fields.last_name?.active" ngDefaultControl id="invoice_last_name" name="invoice_last_name" [labelText]="fields.last_name.name" 
    [required]="fields.last_name.required && guestAddress?.manual && guestAddress.address_type !== AddressType.company"
    [placeholder]="fields.last_name.name" [errorMessage]="'validation.last_name' | translate"
    [(ngModel)]="guestAddress.last_name" (valueChange)="updateAddress()">
  </straiv-input>
</div>

<straiv-input *ngIf="fields.address?.active" ngDefaultControl id="invoice_address" name="invoice_address"
  [labelText]="fields.address.name" [placeholder]="fields.address.name" [required]="fields.address.required && guestAddress?.manual" 
  [errorMessage]="'validation.address' | translate" [(ngModel)]="guestAddress.address" (valueChange)="updateAddress()">
</straiv-input>

<div class="zipcode-grid" [class.special]="states?.length">
  <straiv-input *ngIf="fields.zipcode?.active" ngDefaultControl id="invoice_zipcode" name="invoice_zipcode" [labelText]="fields.zipcode.name" 
    [placeholder]="fields.zipcode.name" [required]="fields.zipcode.required && guestAddress?.manual"
    [errorMessage]="'validation.zip' | translate" [(ngModel)]="guestAddress.zipcode" (valueChange)="updateAddress()">
  </straiv-input>
  
  <straiv-input *ngIf="fields.city?.active" ngDefaultControl id="invoice_city" name="invoice_city" [labelText]="fields.city.name" 
    [placeholder]="fields.city.name" [required]="fields.city.required && guestAddress?.manual"
    [errorMessage]="'validation.city' | translate" [(ngModel)]="guestAddress.city" (valueChange)="updateAddress()">
  </straiv-input>
</div>
