<ng-container *ngIf="data && !deviatingBillingAdr && usedAddress && cicoService.folioAddressStep !== 'create'">
  <div *ngIf="newDesign && cicoService.folioAddressStep === 'folio'">
    <div class="folio-header" *ngIf="!isStaticAddress(); else addressReadOnly">
      <label *ngIf="usedAddress.valid()" [for]="'address_' + number + '_' + 0" class="address redesign" data-cy="address-block" [innerHtml]="usedAddress.folioAddressInfoEmpty() ? 'address.deviating_invoice_address' : usedAddress.htmlBlock(true)" translate></label>
      <span *ngIf="!usedAddress.valid()" class="error" (click)="editInvoiceAdr()">{{'address.valid_adr' | translate}}</span>
      <span class="edit main-text btn-outline" (click)="editInvoiceAdr()" data-cy="edit-address" translate>
        address.edit_address
      </span>
    </div>

    <ng-template #addressReadOnly>
      <div class="address" data-cy="address-block" [innerHtml]="usedAddress.isEmpty() ? 'address.valid_adr' : usedAddress.htmlBlock(true)" translate></div>
    </ng-template>
  </div>
</ng-container>

<ng-container *ngIf="(data && newDesign) || deviatingBillingAdr">
  <straiv-section-header [heading]="'address.billing_address' | translate" [description]="'address.business_trip_company_name_mandatory' | translate"> </straiv-section-header>
  <div *ngIf="cicoService.folioAddressStep === 'select'">
    <div class="invoices-container">
      <div class="heading-container">
        <button class="btn-filled" [disabled]="fieldsInactive || addresses.length >= maxAdresses" data-cy="new-address-button" (click)="addNewAdrs()" type="button" translate>misc.new</button>
        <h2 translate *ngIf="!withoutFolio">service.folios.invoice_address</h2>
      </div>
      <div class="addresses-list" *ngIf="!cicoService.hideAddressList">
        <ng-container *ngFor="let address of addresses | addressPipe; let i = index">
          <app-address-item [address]="address" [index]="i" [changeable]="!fieldsInactive && address.manual" [checked]="isAddressSelected(i)" (checkedChange)="addressChooser(i)" (editClick)="editAddress($event)" (deleteClick)="deleteAddress($event)"></app-address-item>
        </ng-container>
      </div>
    </div>
  </div>

  <ng-container *ngIf="((usedAddress?.manual && cicoService.folioAddressStep === 'create') || deviatingBillingAdr) && fields && !isStaticAddress()">
    <div id="address-form">
      <pms-address [guestAddress]="usedAddress" [fieldType]="'invoice_address'" [field]="fields" [data]="data" [isBillingAddress]="deviatingBillingAdr" (onChangeCountry)="handleCountryChange($event)"></pms-address>
    </div>
  </ng-container>
</ng-container>
