<header class="main-shadow" [class]="business.style.theme">
  <div *ngIf="isHomeModule" class="icon" (click)="showSearch()" [ngClass]="{'disabled_state': offline}">
    <i class="fas fa-search"></i>
    <span>{{'misc.search' | translate}}</span>
  </div>

  <a *ngIf="!isHomeModule" class="icon" [routerLink]="['/g/', globals.getCode()]" (click)="resetProcess()">
    <i class="fas fa-home"></i>
    <span>{{'footer.home' | translate }}</span>
  </a>

  <app-widget-logo [fillContainer]="false"></app-widget-logo>

  <a class="icon" [routerLink]="['/g/', globals.getCode(), 'language']" *ngIf="multilingual && isHomeModule" [ngClass]="{'disabled_state': offline}">
    <img *ngIf="languageService.language?.language && languageService.language?.flag" [src]="languageService.language.flag" [alt]="languageService.language.language" class="flag">
    <i *ngIf="!languageService.language?.flag" class="fas fa-globe-americas"></i>
    <span *ngIf="languageService.language?.language">{{languageService.language.language}}</span>
  </a>

  <a class="icon" href="tel:{{business.contact.phone}}" *ngIf="!multilingual && business">
    <i class="fas fa-phone"></i>
    <span>{{'misc.call' | translate}}</span>
  </a>
</header>
