<form #form="ngForm" class="redesign" *ngIf="data?.module?.type === ciCoType.co">
  <fieldset class="co-container">
    <ng-container *ngIf="data.incident?.reservation?.primary_guest">
      <straiv-reservation-card is-upgrade-card="false" selectable="false" [imageUrl]="reservationImageUrl"
        [extrasHeading]="extrasHeading" [extras]="extras" [heading]="data.incident.reservation?.unit?.name"
        [arrivalDate]="arrival" [departureDate]="departure" [checkInTime]="checkInTime" [checkOutTime]="checkOutTime"
        [errorMessage]="'validation.required' | translate"></straiv-reservation-card>

      <straiv-input ngDefaultControl [name]="'email'" placeholder="yourname@mail.de" type="email" mode="email"
        [required]="field_for('email').required" [(ngModel)]="data.incident.reservation.primary_guest.email"
        [errorMessage]="'validation.required' | translate" [labelText]="field_for('email').name"></straiv-input>

      <app-pms-invoice-address-form [data]="data" *ngIf="ownInvoiceAddress"></app-pms-invoice-address-form>

      <app-form-fields [form]="data.incident" [submited]="submited" [module]="data.module" [redesign]="true"
        #fieldsComponent *ngIf="data.module.fields"></app-form-fields>
    </ng-container>
  </fieldset>
</form>