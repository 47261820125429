<section id="logo" class="main-shadow" [class.upright]="logo_upright" [class.fill-container]="fillContainer" [class]="business.style.theme">
  <a [routerLink]="['/g', globals.getCode()]" [class.no-events]="wizard" *ngIf="business?.logo">
    <img class="img-responsive" [src]="business.logo" [alt]="business.name" />
  </a>
  <h3 [innerText]="title" *ngIf="title"></h3>
</section>
<div class="info" *ngIf="wizard && loaded && (!socketConnected || globals.offline)">
  <i class="fas fa-exclamation-triangle"></i>
  <span [innerHTML]="'service.kiosk.websocket' | translate" *ngIf="!socketConnected && !globals.offline"></span>
  <span [innerHTML]="'service.kiosk.offline' | translate" *ngIf="globals.offline "></span>
</div>
