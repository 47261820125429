import { Component, Input, OnInit } from '@angular/core';
import { PmsCiCoService } from 'cico_service';
import { CusInfo } from 'models/pms/pms_reservation';
import { ConfirmName, CusProductTypes } from 'pms_enums';
import { CurrencyPipe } from '@angular/common';
import { Globals } from 'base';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})

export class ProductComponent implements OnInit {
  @Input() index = 0;
  @Input() product: any;
  @Input() rule: any;
  @Input() totalGuestCount: number;
  @Input() nightsCount: number;
  @Input() reservationCusInfo: CusInfo;

  images: any[] = [];
  previewSrc: any;
  showPreview: boolean = false;
  perNightCharge: string;
  totalCUSPrize: string;

  constructor(public cicoService: PmsCiCoService,
              private currencyPipe: CurrencyPipe) { }

  ngOnInit(): void {
    this.cicoService.cusSelectedProduct = this.product;
    this.images = this.product?.medias || [];
  }

  nextImg() {
    const lastElement = this.images.pop();
    this.images.unshift(lastElement);
  }

  prevImg() {
    const firstElement = this.images.shift();
    this.images.push(firstElement);
  }

  photoClick(photo: any) {
    this.showPreview = true;
    this.previewSrc = photo;
  }

  handleProductBooking() {
    if (this.cicoService.productsBooking.toBeBooked) {
      if (this.product.kind === CusProductTypes.roomCategory && this.cicoService.cusSelectedProduct === this.product) {
        this.cicoService.cusSelectedProduct = this.product.offers[0];
      }
      this.cicoService.totalGrossService = this.transformAmount(this.totalPrice(), this.cicoService.cusSelectedProduct.currency);
      this.cicoService.confirmNameSubj.next(ConfirmName.add_product);
    } else {
      if (this.product.kind === CusProductTypes.roomCategory) {
        this.cicoService.cusSelectedProduct = this.product;
      }
      this.cicoService.confirmNameSubj.next(ConfirmName.next);
    }
  }

  handleItemSelected(selectedItem: any) {
    this.cicoService.cusSelectedProduct = selectedItem;
    this.cicoService.productsBooking.toBeBooked = true
    this.handleProductBooking();
  }

  transformAmount(amount: number, currencyCode: string): string | null {
    return this.currencyPipe.transform(amount, currencyCode, 'symbol', '1.2-2');
  }

  totalPrice() {
    const chosenProduct = this.cicoService.cusSelectedProduct
    const isRoomCategory  = chosenProduct.kind === CusProductTypes.roomCategory
    const additionalFactor = isRoomCategory ? 1 : this.totalGuestCount;

    const basePrice = isRoomCategory ? chosenProduct.difference : this.product.gross;

    return basePrice * additionalFactor * this.nightsCount;
  }

}
