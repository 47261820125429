import { FieldValue } from '../field_value';
import { PmsReservation } from './pms_reservation';

export class GenericCheckOut {
  reservation: PmsReservation;
  field_values: FieldValue[] = [];
  prevented: boolean;
  prevention: string;
  steps: any = {};
  checks: any = {};
  transportation: string;

  constructor(raw_json: any) {
    this.reservation = new PmsReservation(raw_json.reservation || raw_json);
    this.prevented = raw_json.prevented || {};
    this.prevention = raw_json.prevention || {};
    this.checks = raw_json.checks || {};

    for (const field of raw_json?.field_values || []) {
      this.field_values.push(new FieldValue(field));
    }
  }

  getStorageKey(): string {
    return 'co_incident_' + this.reservation?.reservation_id;
  }
}
