import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { PmsPinCodesModule } from '../../check_in/pin_codes/pin_codes.module';
import { PmsEncoderModule } from '../encoder/encoder.module';
import { InfoModule } from '../info/info.module';
import { PmsCheckInConfirmRoutingModule } from './confirm-routing.module';
import { PmsCheckInConfirmComponent } from './confirm.component';
import { PmsBaseDirective } from 'pms_base/base.directive';
import { ConfirmModule } from 'modules/confirm/confirm.module';
import { LoaderModule } from 'common/loader/loader.module';
import { LegalModule } from 'shared/forms/legal/legal.module';
import { SuccessScreenModule } from '../success-screen/success-screen.module';
import { PmsSignatureModule } from '../signature/signature.module';
import { InfoScreenModule } from '../info-screen/info-screen.module';

@NgModule({
  imports: [
    CommonModule,
    PmsCheckInConfirmRoutingModule,
    ConfirmModule,
    TranslateModule,
    InfoModule,
    LoaderModule,
    LegalModule,
    SuccessScreenModule,
    InfoScreenModule,
    PmsSignatureModule,
    PmsEncoderModule,
    PmsPinCodesModule
  ],
  exports: [
    PmsCheckInConfirmComponent
  ],
  declarations: [PmsCheckInConfirmComponent],
  providers: [
    PmsBaseDirective
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class PmsCheckInConfirmModule {}
