<ng-container *ngIf="data">
  <ng-container *ngIf="!pending">

    <div class="content-container animation-out" *ngIf="!authUrl?.length && !authFailed">
      <div *ngIf="nationalityField" class="nationality-selection">
        <straiv-select inputId="nationality" [items]="data.countriesNat | json" [value]="guest.nationality" [labelHeader]="nationalityField.name" [placeholder]="nationalityField.name" (selectedEvent)="selectNationality($event)" [errorMessage]="'validation.nationality' | translate"></straiv-select>
      </div>

      <div class="verification-selection animation-in" *ngIf="verificationOptions?.length">
        <straiv-section-header [heading]="'service.check_in.authentication.description' | translate"></straiv-section-header>
        <div class="actions-grid">
          <straiv-actioncard *ngFor="let option of verificationOptions" [text]="option.text | translate" [icon]="'fas fa-' + option.icon" (cardClick)="idfMethod(option.method)"></straiv-actioncard>
        </div>
      </div>
    </div>

    <div class="iframe_container" *ngIf="authUrl?.length && !authFailed">
      <iframe [src]="authUrl | safe" id="external_link" allow="camera *; microphone *" allowfullscreen=""></iframe>
    </div>

    <div *ngIf="authFailed" class="animation-in">
      <div class="desc">
        <div class="instructions not-first-instruction" [innerHTML]="ui_messages.auth_failed?.content"></div>
      </div>
      <div class="button-row">
        <button type="button" class="btn-filled" (click)="retryAuth()" translate>service.folios.retry</button>
        <button type="button" class="btn-outline" (click)="manual()" *ngIf="authRequired === 'optional'" translate>service.check_in.authentication.manual</button>
      </div>
    </div>
  </ng-container>

  <app-loader [text]="'service.check_in.authentication.pending'" *ngIf="pending"></app-loader>
</ng-container>
