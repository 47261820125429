<!-- 
<div class="segment-button product-button">
  <straiv-segment-button ngDefaultControl id="product" name="product" [leftText]="'cus.skip_breakfast' | translate"
    [rightText]="'cus.order_breakfast' | translate" left-value="skip_breakfast"
    right-value="order_breakfast" [(ngModel)]="cicoService.productsBooking.toBeBooked"
    [value]="cicoService.productsBooking.toBeBooked ? ('cus.order_breakfast' | translate) : ('cus.skip_breakfast' | translate)"
    (buttonClick)="breakfastToggle($event)">
  </straiv-segment-button>
</div>

<ng-container>
  <straiv-reservation-card
    is-upgrade-card="true"
    size="medium"
    [imageUrl]="images[0].media.url"
    [heading]="product.name"
    [description]="product.description"
    [roomAmount]="totalCUSPrize"
    amount-text="from"
    [perNightCharge]="perNightCharge">
  </straiv-reservation-card>
</ng-container> -->
<div class="product-info">
  <app-radio-group class="animation-out" [inputId]="'product'+index" [(ngModel)]="cicoService.productsBooking.toBeBooked" [description]="(product.kind !== 'room_category' ? 'cus.breakfast' : 'cus.room_upgrade') | translate : { productName: product.name }" (ngModelChange)="handleProductBooking()" [options]="[['misc.yes_string', true], ['misc.no_string', false]]"></app-radio-group>

  <div *ngIf="images.length" class="carousel-container">
    <div class="carousel">
      <div class="arrow left-arrow">
        <svg class="icon primary-stroke" width="16" height="16" viewBox="0 0 16 16" fill="none" (click)="prevImg()">
          <path d="M6.38086 3.95312L2.33419 7.99979L6.38086 12.0465" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M13.666 8H2.44602" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </div>

      <img [src]="images[0].media.url" alt="Carousel Image" (click)="photoClick(images[0].media.url)">

      <div class="arrow right-arrow">
        <svg class="icon primary-stroke" width="16" height="16" viewBox="0 0 16 16" fill="none" (click)="nextImg()">
          <path d="M9.61914 3.95312L13.6658 7.99979L9.61914 12.0465" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M2.33398 8H13.554" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </div>
    </div>
  </div>

  <div class="price" *ngIf="product.kind !== 'room_category'">
    {{product.gross | currency:product.currency:true}}
  </div>

  <div class="reservation-container" *ngIf="reservationCusInfo && product.kind === 'room_category'">
    <div class="room-category">
      <span class="reservation-title smaller-font-size" translate>misc.category_name</span> <span class="info smaller-font-size">{{ reservationCusInfo?.unitName }}</span>
    </div>
    <div class="date-info">
      <div class="arrival">
        <span class="reservation-title smaller-font-size" translate>booking_data.arrival</span> <span class="info smaller-font-size">{{ reservationCusInfo?.arrival }}</span>
      </div>
      <div class="departure">
        <span class="reservation-title smaller-font-size" translate>booking_data.departure</span> <span class="info smaller-font-size">{{ reservationCusInfo?.departure }}</span>
      </div>
    </div>
  </div>

  <p class="description" *ngIf="product?.description?.length && product.kind !== 'room_category'">{{product.description}}</p>
  <!-- <span class="see-more main-text">See More</span> -->

  <div class="info" *ngIf="product.kind !== 'room_category'">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
      <circle cx="12" cy="12" r="11.5" fill="white" class="main-stroke" />
      <path d="M11 18H13.4V10.8H11V18ZM12.2 8.4C12.54 8.4 12.8252 8.2848 13.0556 8.0544C13.286 7.824 13.4008 7.5392 13.4 7.2C13.4 6.86 13.2848 6.5748 13.0544 6.3444C12.824 6.114 12.5392 5.9992 12.2 6C11.86 6 11.5748 6.1152 11.3444 6.3456C11.114 6.576 10.9992 6.8608 11 7.2C11 7.54 11.1152 7.8252 11.3456 8.0556C11.576 8.286 11.8608 8.4008 12.2 8.4Z" class="main-fill" />
    </svg>

    <div translate>cus.info</div>
  </div>

  <app-photo-preview class="animation-out" *ngIf="showPreview" [photoSrc]="previewSrc" (close)="showPreview = false"></app-photo-preview>
</div>

<app-upsell-gallery *ngIf="product.kind === 'room_category'" [upsellItems]="product.offers" [currentPrice]="product.gross" (itemSelected)="handleItemSelected($event)"></app-upsell-gallery>
